import config from '../config/config';
import callApi from '../util/callApi';
import { postCouponRequestBody } from './types';

const method = 'PATCH';

export function postCoupon(body: postCouponRequestBody) {
    return callApi({
        url: config.apiUrl.useTheCoupon,
        method,
        body,
    });
}
